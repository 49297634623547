export const releaseNotesEn: ReleaseNotes = [
    {
        release: "0.1.0",
        date: "2023-10-19",
        tags: [
            "Base System"
        ],
        type: "minor",
        notes: [
            {
                "description": "First release of the base system."
            }
        ]
    },
    {
        release: "0.0.0",
        date: "2023-09-01",
        tags: [
            "Start of Project"
        ],
        type: "major",
        notes: [
            {
                "description": "The project has been deployed. Development can commence!"
            }
        ]
    }
]