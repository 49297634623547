import {useAuthStore} from "../store/auth";

export const useAppVersion = () => {
    const authStore = useAuthStore()

    const {app} = useRuntimeConfig()

    const remoteVersion = computed(() => {
        return authStore.remoteVersion
    })

    const versionIsUpToDate = computed(() => {
        return remoteVersion.value && (app?.clientVersion === remoteVersion.value)
    })

    const versionMismatch = computed(() => {
        return remoteVersion.value && (app?.clientVersion !== remoteVersion.value)
    })

    return {
        versionIsUpToDate,
        versionMismatch
    }
}